import '../styles/index.scss';
import $ from "jquery";
import 'selectric';
import 'magnific-popup';
import Inputmask from "inputmask";
import ls from 'local-storage';
import _ from 'lodash';

// CONFIGS
var post_url = 'https://www.reallygreatrate.com/formdov.php';

/*
   HELPERS
 */
/*
 * Basic email validation
 */
function validEmail(email) {
    var pattern = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    return $.trim(email).match(pattern) ? true : false;
}
/*
 * Basic zipcode validation
 */
function isValidZipCode(zip) {
    return /^\d{5}(-\d{4})?$/.test(zip);
}
/*
 * Sets hash value
 */
function setHash(hash) {
    // if(history.pushState) {
    //     history.pushState(null, null, hash);
    // }
    // else {
    //     location.hash = hash;
    // }
}
/*
 * Checks if value in array
 */
function isInArray(value, array) {
    return array.indexOf(value) > -1;
}

var get_params = function(search_string) {
    var parse = function(params, pairs) {
        var pair = pairs[0];
        var parts = pair.split('=');
        var key = decodeURIComponent(parts[0]);
        var value = decodeURIComponent(parts.slice(1).join('='));

        // Handle multiple parameters of the same name
        if (typeof params[key] === "undefined") {
            params[key] = value;
        } else {
            params[key] = [].concat(params[key], value);
        }

        return pairs.length == 1 ? params : parse(params, pairs.slice(1));
    };

    // Get rid of leading ?
    return search_string.length == 0 ? {} : parse({}, search_string.substr(1).split('&'));
};
/*
 USER IP
 */
$.ajax({
    url : '//www.reallygreatrate.com/api/user/ip_address/',
    dataType : 'json',
    method: 'get',
    data: {},
    success: function (response) {
        $('#user_ip').val(response['ip_address']);
    }
});
/*
   MAGNIFIC POPUP
 */
$('.mfp-iframe').each(function() { // the containers for all your galleries
    $(this).magnificPopup({
        type: 'iframe',
        mainClass: 'iframe-popup'
    });
});

$('.mfp-inline').each(function() { // the containers for all your galleries
    $(this).magnificPopup({
        type: 'inline'
    });
});

/*
    POPUP
*/
function showPopUp(updatHash = true) {
    setTimeout(function () {
        $('.step-form .step.active .radio-button a').first().focus();
    },301);
    $('html').addClass('popup-open');
    $('.popup').fadeIn();
    $(window).scrollTop(0);
    if(updatHash) setHash('#2');
}
function hidePopUp() {
    $('html').removeClass('popup-open');
    $('.popup').fadeOut();
    setHash('');
}
/*
    GET Default params
 */
var params = get_params(location.search);
// publisher_id
if(typeof params.publisher_id !== "undefined") {
    $('input[name="publisher_id"]').val(params.publisher_id);
} else {
    $('input[name="publisher_id"]').val(632);
}
// subid
if(typeof params.subid !== "undefined") {
    $('input[name="subid"]').val(params.subid);
} else {
    $('input[name="subid"]').val('');
}
// rcid
if(typeof params.rcid !== "undefined") {
    $('input[name="rcid"]').val(params.rcid);
} else {
    $('input[name="rcid"]').val('');
}
/*
    HID
 */
$.ajax({
    url : '//www.reallygreatrate.com/api/hdi/?upload_type=' + $('input[name="upload_type"]').val() + '&publisher_id=' + $('input[name="publisher_id"]').val(),
    dataType : 'json',
    method: 'get',
    data: {},
    success: function (response) {
        $('input[name="hid"]').val(response['hid']);
    }
});

/*
    ZIP CODE
 */
$('.start-form button').click(function (e) {
    e.preventDefault();
    var $el = $(this).prev('input');
    var $button = $(this);
    var value = $el.val();
    var $form = $el.parent('form');
    $form.find('.error').fadeOut();
    $el.removeClass('input-error');

    if(!$button.is(":disabled")) {
        $button.prop('disabled', true);
        if(value === "" || !isValidZipCode(value)) {
            $el.addClass('input-error');
            $form.find('.error').fadeIn();
        } else {
            getCity(value);
        }
        $button.prop('disabled', false);
    }
});

/*
 * Get city name
 */
var zip_response = null;
function getCity(value) {
    // Check zip code
    $.ajax({
        url: '//www.reallygreatrate.com/api/zipcode_lookup/?zip=' + value,
        dataType: 'json',
        method: 'get',
        data: {'zip': value},
        success: function (response) {
            zip_response = response;
            zip_response['zip'] = value;

            $('input[name="zip"]').val(value);
            $('input[name="city"]').val(response['city']);
            $('input[name="state"]').val(response['state']);

            $('.street-info').text(response['city']+', '+response['state']+', '+value);
            $('.city-title-popup').text(response['city']);

            ls('zip', value);
            ls('city', response['city']);
            ls('state', response['state']);

            if (response['city']) {
                showPopUp();
            } else {
                $el.addClass('input-error');
                $form.find('.error').fadeIn();
            }
        }
    });
}

$(document).on('change', '.radio-list:visible input', function () {
    $('.radio-list:visible li').removeClass('active');
    $(this).parent('label').parent('li').addClass('active');
});

var step_2 = {
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');
            if (!$('.step-form .step.active input[name="field_1"]:checked').val()) {
                $('.step-form .step.active .errors').html('<span class="error">Please Select Home Type</span>');
                $('.step-form .step.active').addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

var step_3 = {
    select : function () {
        // Init custom selects
        $('#property-value').selectric({
            nativeOnMobile: true,
            onChange: function (element) {
                $('.select-wrap').addClass('changed');
                var el_val = $(element).find('option:selected').val();
                var el_text = $(element).find('option:selected').text();
                //if(el_text !== 'Other') {
                //    $('input[name="provider-radio"]').prop('checked', false);
                 //   $('input[name="field_2"]').val(el_val);
                    //$('input[name="field_2"]').next('span').text(el_text);
                //}
            }
        });
        //$(document).on('change', '#property-value', function () {
            //var val = $('#property-value option:selected').val();
            //$('input[name="property-value"]').prop('checked', false);
            //$('input[name="field_2"]').val(val);
        //});

        $('#mortgage-balance').selectric({
            nativeOnMobile: true,
            onChange: function (element) {
                $('.select-wrap').addClass('changed');
                var el_val = $(element).find('option:selected').val();
                var el_text = $(element).find('option:selected').text();
                //if(el_text !== 'Other') {
                //$('input[name="mortgage-balance"]').prop('checked', false);
                //$('input[name="field_2"]').val(el_val);
                //$('input[name="field_2"]').next('span').text(el_text);
                //}
            }
        });
        $(document).on('change', 'mortgage-balance', function () {
            //var val = $('#property-value option:selected').val();
            //$('input[name="mortgage-balance"]').prop('checked', false);
            //$('input[name="field_2"]').val(val);
        });
    },
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');

            var is_prop_selected = ($('#property-value option:selected').length > 0);
            var is_mort_selected = ($('#mortgage-balance option:selected').length > 0);
            if(!is_prop_selected && !is_mort_selected) {
                $('.step-form .step.active .errors').html('<span class="error">Please select estimated property value and estimated mortgage balance</span>');
                $('.step-form .step.active').addClass('has-errors');
            } else {
                if(!is_prop_selected) {
                    $('.step-form .step.active .errors').html('<span class="error">Please select estimated property value</span>');
                    $('.step-form .step.active').addClass('has-errors');
                }
                if(!is_mort_selected) {
                    $('.step-form .step.active .errors').html('<span class="error">Please select estimated mortgage balance</span>');
                    $('.step-form .step.active').addClass('has-errors');
                }
            }
            $button.prop('disabled', false);
        }
    }
};

step_3.select();

var step_4 = {
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');
            if (!$('.step-form .step.active input[name="field_6"]:checked').val()) {
                $('.step-form .step.active .errors').html('<span class="error">Please select how you would rate your credit</span>');
                $('.step-form .step.active').addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

var step_5 = {
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');
            if (!$('.step-form .step.active input[name="cash_out"]:checked').val()) {
                $('.step-form .step.active .errors').html('<span class="error">Please select if you would like to take cash out</span>');
                $('.step-form .step.active').addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

var step_6 = {
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');
            if (!$('.step-form .step.active input[name="field_34"]:checked').val()) {
                $('.step-form .step.active .errors').html('<span class="error">Please select your employment status</span>');
                $('.step-form .step.active').addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

var step_7 = {
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');
            if (!$('.step-form .step.active input[name="field_23"]:checked').val()) {
                $('.step-form .step.active .errors').html('<span class="error">Please select if your mortgage is FHA</span>');
                $('.step-form .step.active').addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

var step_8 = {
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');
            if (!$('.step-form .step.active input[name="field_28"]:checked').val()) {
                $('.step-form .step.active .errors').html('<span class="error">Please select if you are a veteran or active military</span>');
                $('.step-form .step.active').addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

var step_9 = {
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');
            // First name
            var first_name = $('.step-form .step.active input[name="first_name"]').val();
            first_name = $.trim(first_name);
            // Last name
            var last_name = $('.step-form .step.active input[name="last_name"]').val();
            last_name = $.trim(last_name);
            // Email
            var email = $('.step-form .step.active input[name="email_address"]').val();
            email = $.trim(email);

            if (first_name === "" && last_name === "" && email === "") {
                $('.step-form .step.active .errors').append('<span class="error">Please fill in your First Name, Last Name and Email</span>');
                $('.step-form .step.active').addClass('has-errors');
            } else {
                if (first_name === "") {
                    $('.step-form .step.active .errors').append('<span class="error">Please fill in your First Name</span>');
                    $('.step-form .step.active').addClass('has-errors');
                }
                if (last_name === "") {
                    $('.step-form .step.active .errors').append('<span class="error">Please fill in your Last Name</span>');
                    $('.step-form .step.active').addClass('has-errors');
                }
                if(email === "") {
                    $('.step-form .step.active .errors').append('<span class="error">Please fill in your Email Address</span>');
                    $('.step-form .step.active').addClass('has-errors');
                } else {
                    if(!validEmail(email)) {
                        $('.step-form .step.active .errors').append('<span class="error">Please check your Email Address formatting (email@domain.com)</span>');
                        $('.step-form .step.active').addClass('has-errors');
                    }
                }
            }
            $button.prop('disabled', false);
        }
    }
};

var step_10 = {
    validate : function () {
        var $button = $('.step-form .step.active button');
        if(!$button.is(":disabled")) {
            $('.step-form .step.active .errors').empty();
            $button.prop('disabled', true);
            $('.step-form .step.active').removeClass('has-errors');
            // Phone
            var phone = $('.step-form .step.active input[name="home_phone"]').val();
            phone = $.trim(phone);
            phone = phone.replace(/[^0-9]/g, '');
            // Adress
            var street = $('.step-form .step.active input[name="street_address"]').val();
            street = $.trim(street);

            if (phone === "" && street === "") {
                $('.step-form .step.active .errors').append('<span class="error">Please fill in your Phone Number</span>');
                $('.step-form .step.active').addClass('has-errors');
            } else {
                if(phone !== "") {
                    if(phone.length < 10) {
                        $('.step-form .step.active .errors').append('<span class="error">Please check Phone Number formatting (9999999999)</span>');
                        $('.step-form .step.active').addClass('has-errors');
                    }
                }
                if (street === "") {
                    $('.step-form .step.active .errors').append('<span class="error">Please fill in your Street Address</span>');
                    $('.step-form .step.active').addClass('has-errors');
                }
            }

            $button.prop('disabled', false);
        }
    }
};

function changeStep(step) {
    setHash('#'+step);
    $('.step-form .step').removeClass('active');
    $('.step-form .step[data-step="'+step+'"]').addClass('active');
    $('.form-top .steps .current').text(step);
    //$('.step-form .step.active input').first().focus();
    switch(step) {
        case 3:
            //$('input[name="field_2"]').val($('input[name="provider-radio"]:checked').val());
            break;
        case 6:
            // Add input mask
            var selector = document.getElementById("home_phone");
            var im = new Inputmask("(999) 999-9999");
            im.mask(selector);
            break;
    }
}

$(document).on('click', '.step-back', function (e) {
    e.preventDefault();
    var step = $('.step-form .step.active').data('step') - 1;
    if(step > 1) {
        changeStep(step);
    } else {
        // hide popup
        hidePopUp();
    }
});

$(document).on('submit', 'form[name="leadform"]', function (e) {
    e.preventDefault();
});

$(document).on('click', '.step-button:visible', function (e) {
    e.preventDefault();
    var step = $(this).data('step');
    if(step === 'submit') {
        step_10.validate();
        if(!$('.step-form .step.active').hasClass('has-errors')) {
            //submit
            var formData = $('#leadform').serialize();
            // Ajax call to post data
            $.ajax({
                url: post_url,
                method: 'GET',
                crossDomain: true,
                data: formData,
                headers: {
                    'Access-Control-Allow-Origin': '*'
                },
                success: function (results) {
                    var iframe_html = '';
                    if(_.has('results', 'pixel')) {
                        if(!_.isUndefined(results.pixel["pixel"])) {
                            iframe_html = results.pixel["pixel"];
                            $('body').append(iframe_html);
                        }
                    }
                    $('.step.active').removeClass('active');
                    $('.step.step-conformation').addClass('active');
                    $('.form-top .steps').empty();
                }
            });

        }
    } else {
        switch(step - 1) {
            case 2:
                step_2.validate();
                break;
            case 3:
                step_3.validate();
                break;
            case 4:
                step_4.validate();
                break;
            case 5:
                step_5.validate();
                break;
            case 6:
                step_6.validate();
                break;
            case 7:
                step_7.validate();
                break;
            case 8:
                step_8.validate();
                break;
            case 9:
                step_9.validate();
                break;
            case 10:
                step_10.validate();
                break;
        }
        if(!$('.step-form .step.active').hasClass('has-errors')) {
            changeStep(step);
        }
    }
});

// Opt out form
$(document).on('click', '#opt-out .opt-out-button', function(e) {
    e.preventDefault();
    var email1 = $.trim($('#emailaddr').val());
    var email2 = $.trim($('#emailaddr2').val());
    var $errors = $('#opt-out .errors');
    var $button = $('#opt-out .opt-out-button');
    $errors.empty();
    if(!$button.is(":disabled")) {
        $button.prop('disabled', true);
        if(email1.length === 0 && email2.length === 0) {
            $errors.append('<span class="error">Must fill Email and Confirm Email fields</span>');
        } else {
            if(email1.length === 0) {
                $errors.append('<span class="error">Must fill Email field</span>');
            } else if(email2.length === 0) {
                $errors.append('<span class="error">Must fill Confirm Email field</span>');
            } else {
                if(!validEmail(email1) && !validEmail(email2)) {
                    $errors.append('<span class="error">Please enter valid Email and Confirm Email</span>');
                } else {
                    if(!validEmail(email1)) {
                        $errors.append('<span class="error">Please enter valid Email</span>');
                    } else if(!validEmail(email2)) {
                        $errors.append('<span class="error">Please enter valid Confirm Email</span>');
                    } else {
                        $.ajax({
                            url : '//www.reallygreatrate.com/api/unsub/?emailaddr=' + email1 + '&table=solar',
                            dataType : 'json',
                            method: 'get',
                            data: {},
                            success: function (response) {
                                if(response['status'] == 'success') {
                                    $('.opt-out-form .form-content').html('Thank you! Your request will be processed in 24-48 hours.');
                                }
                            }
                        });
                    }
                }
            }
        }
        $button.prop('disabled', false);
    }
});

// Tooltip
$(document).on('click', 'a.tooltip', function(e) {
    e.preventDefault();
});

// Use for the GeoIP2 JavaScript API is to fill state name
var fillState = (function () {
    var getStateName = function (geoipResponse) {
        /*
            most_specific_subdivision returns the smallest available
            subdivision (region) as defined in ISO 3166-2.
        */
        var regionName = geoipResponse.most_specific_subdivision.names.en || '';
        if(regionName) {
            document.getElementById("header-state-text").innerHTML = regionName + ' ';
        }
    };
    var onSuccess = function (geoipResponse) {
        getStateName(geoipResponse);
    };
    /* If we get an error we will do nothing*/
    var onError = function (error) {
        return;
    };
    return function () {
        geoip2.city( onSuccess, onError );
    };
}());

var allowedHashes = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

$(document).ready(function() {

    $('input[name=zip_code]').val("");
    $(window).keydown(function(e) {
        if($('input[name=zip_code]').index($(e.target))!=-1) {
            if(
                ($.inArray(e.keyCode, [48,49,50,51,52,53,54,55,56,57,58,96,97,98,99,100,101,102,103,104,105,8,13,190,189]) == -1) // digits, digits in num pad, 'back', 'enter', '.', '-'
                || (e.keyCode == 190 && $(e.target).val().indexOf(".") != -1) // not allow double dot '.'
                || (e.keyCode == 190 && $(e.target).val().length == 0) // not allow dot '.' at the begining
            ) {
                e.preventDefault();
            } else {
                if($(e.target).val().length > 4 && e.keyCode !== 8) {
                    e.preventDefault();
                }
            }
        }
    });

    var hashValue = location.hash;
    hashValue = hashValue.replace(/^#/, '');

    if(isInArray(hashValue,allowedHashes)) {
        if(ls('zip')) {
            getCity(ls('zip'));
            setTimeout(function () {
                showPopUp(false);
                changeStep(hashValue);
            },200);
        }
        setTimeout(function () {
            $('html').addClass('ready');
        },300);
    } else {
        $('html').addClass('ready');
    }

    if($('body').hasClass('#index-page')) {
        fillState();
    }
});


